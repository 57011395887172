import * as React from 'react';
import classnames from 'classnames';

function Heading({
  children,
  level = 'h1',
  visualLevel,
  tag,
  uppercase,
  position,
  weight,
  lineHeight,
  whiteSpace,
  font,
  className,
  ...props
}) {
  const Tag = tag || level;
  const visual = visualLevel || level;

  return (
    <Tag
      className={classnames(
        {
          'text-h1': visual === 'h1',
          'text-h2': visual === 'h2',
          'text-h3': visual === 'h3',
          'text-h4': visual === 'h4',
          'text-h5': visual === 'h5',
          'text-h6': visual === 'h6',
          'font-normal': weight === 'normal',
          'font-bold': weight === 'bold',
          'font-heading': font === 'heading',
          'text-left justify-start': position === 'left',
          'text-center justify-center': position === 'center',
          'text-right justify-end': position === 'right',
          uppercase: uppercase,
          'leading-none': lineHeight === '1',
          'whitespace-nowrap': whiteSpace === 'nowrap',
        },
        className,
      )}
      {...props}
    >
      {children}
    </Tag>
  );
}

export default Heading;
