import React from 'react';
import { SkipNavLink, SkipNavContent } from '@reach/skip-nav';
import { ErrorBoundary } from 'react-error-boundary';
import { StaticImage } from 'gatsby-plugin-image';

import ErrorFallback from '../components/ErrorFallback';
import Header from '../components/Header';
import Footer from '../components/Footer';
import useTranslation from '../hooks/useTranslation';
import Heading from '../components/Heading';

function BackgroundFull() {
  const { t } = useTranslation();
  return (
    <div className="after:bg-gradient-to-t after:from-black after:absolute after:bottom-0 after:left-0 after:right-0 after:h-1/2">
      <StaticImage
        src="../assets/images/magnet-lounge-exterior.jpg"
        formats={['auto', 'webp', 'avif']}
        alt={t('HOMEPAGE.IMAGE_ALT')}
        className="!fixed inset-0"
      />
    </div>
  );
}

function BackgroundHero({ title }) {
  const { t } = useTranslation();
  return (
    <div className="h-80 overflow-hidden relative after:absolute after:inset-0 after:bg-black after:bg-opacity-70">
      <StaticImage
        src="../assets/images/magnet-lounge-interior.jpg"
        formats={['auto', 'webp', 'avif']}
        alt={t('COMMON.HERO_IMAGE_ALT')}
        className="!absolute inset-0"
      />
      {title && (
        <Heading
          level="h2"
          visualLevel="h3"
          weight="bold"
          className="absolute bottom-8 left-4 z-[1] md:hidden"
        >
          {title}
        </Heading>
      )}
    </div>
  );
}

const Layout = ({ children, background, title }) => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <h1 className="sr-only">Magnet Lounge - parim vesipiip Tallinnas</h1>
      <SkipNavLink />
      {background === 'full' && <BackgroundFull />}
      <div className="relative flex flex-col h-full">
        <Header />
        <SkipNavContent className="flex-1">
          <main role="main" className="h-full isolate">
            {background === 'hero' && <BackgroundHero title={title} />}
            {children}
          </main>
        </SkipNavContent>
        <Footer />
      </div>
    </ErrorBoundary>
  );
};

export default Layout;
