import * as React from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/outline';
import { useLocation } from '@reach/router';

import pages from '../../config/pages';
import useTranslation from '../hooks/useTranslation';

function Navigation({ direction, className }) {
  const { t, locale } = useTranslation();
  const location = useLocation();
  const activeClassName = 'underline underline-offset-2 decoration-2';

  function MyLink({ page }) {
    return (
      <Link
        to={page[locale].url}
        className="block px-4 py-2 hover:opacity-80 tracking-wide text-sm font-semibold"
        activeClassName={activeClassName}
        partiallyActive={(() => {
          const pathname = location.pathname;
          return (
            (page[locale].path === '' && pathname === '/') ||
            (page[locale].path !== '' && pathname.includes('/menuu/'))
          );
        })()}
      >
        {t(`NAVIGATION.${page.id}`)}
      </Link>
    );
  }

  function MyMenu({ page }) {
    return (
      <Menu as="div" className="relative">
        <Menu.Button
          className={classNames(
            'px-4 py-2 hover:opacity-80 tracking-wide text-sm font-semibold flex items-center space-x-1',
            {
              [activeClassName]: location.pathname.startsWith(page[locale].url),
            },
          )}
        >
          <span>{t(`NAVIGATION.${page.id}`)}</span>
          <ChevronDownIcon className="h-4 w-4" />
        </Menu.Button>

        <Transition
          as={React.Fragment}
          enter="transition ease-out duration-200"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-right absolute right-0 mt-2 w-36 shadow-lg py-1 bg-black ring-1 ring-black ring-opacity-5 focus:outline-none">
            {page.children.map((child) => (
              <Menu.Item key={child.id}>
                <MyLink page={child} />
              </Menu.Item>
            ))}
          </Menu.Items>
        </Transition>
      </Menu>
    );
  }

  return (
    <nav className={className}>
      <h2 className="sr-only">Navigatsioon</h2>
      <ul
        className={classNames('flex', {
          'flex-col': direction === 'column',
        })}
      >
        {pages
          .filter((page) => page.menu)
          .map((page) => (
            <li key={page.id}>
              {direction !== 'column' &&
                (!page.children ? (
                  <MyLink page={page} />
                ) : (
                  <MyMenu page={page} />
                ))}

              {direction === 'column' && (
                <>
                  <MyLink page={page} />

                  {page.children && (
                    <ul className="flex flex-col pl-8">
                      {page.children.map((child) => (
                        <li key={child.id}>
                          <MyLink page={child} />
                        </li>
                      ))}
                    </ul>
                  )}
                </>
              )}
            </li>
          ))}
      </ul>
    </nav>
  );
}

export default Navigation;
