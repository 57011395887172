module.exports = [
  {
    id: 1,
    et: {
      path: '',
      url: '/',
    },
    menu: true,
    default: true,
    template: 'Homepage',
  },
  {
    id: 2,
    et: {
      path: 'meist',
      url: '/meist/',
    },
    menu: true,
    template: 'About',
  },
  {
    id: 3,
    et: {
      path: 'menuu',
      url: '/menuu/',
    },
    menu: true,
    children: [
      {
        id: 6,
        et: {
          path: 'menuu/soogid',
          url: '/menuu/soogid/',
        },
        menu: false,
        submenu: true,
        template: 'MenuChildren',
        name: 'FOOD',
      },
      {
        id: 7,
        et: {
          path: 'menuu/kulmad-joogid',
          url: '/menuu/kulmad-joogid/',
        },
        menu: false,
        submenu: true,
        template: 'MenuChildren',
        name: 'COLD_DRINKS',
      },
      {
        id: 8,
        et: {
          path: 'menuu/soojad-joogid',
          url: '/menuu/soojad-joogid/',
        },
        menu: false,
        submenu: true,
        template: 'MenuChildren',
        name: 'HOT_DRINKS',
      },
      {
        id: 9,
        et: {
          path: 'menuu/lahja-alkohol',
          url: '/menuu/lahja-alkohol/',
        },
        menu: false,
        submenu: true,
        template: 'MenuChildren',
        name: 'ALCOHOL',
      },
      {
        id: 10,
        et: {
          path: 'menuu/kange-alkohol',
          url: '/menuu/kange-alkohol/',
        },
        menu: false,
        submenu: true,
        template: 'MenuChildren',
        name: 'STRONG_ALCOHOL',
      },
      {
        id: 11,
        et: {
          path: 'menuu/vesipiip',
          url: '/menuu/vesipiip/',
        },
        menu: false,
        submenu: true,
        template: 'MenuChildren',
        name: 'SHISHA',
      },
    ],
    template: 'Menu',
  },
  {
    id: 4,
    et: {
      path: 'galerii',
      url: '/galerii/',
    },
    menu: true,
    template: 'Gallery',
  },
  {
    id: 5,
    et: {
      path: 'kontakt',
      url: '/kontakt/',
    },
    menu: true,
    template: 'Contact',
  },
];
