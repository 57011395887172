import * as React from 'react';
import { Link } from 'gatsby';
import { useWindowScroll } from 'react-use';
import classNames from 'classnames';

import LogoSVG from '../assets/svg/logo-big.svg';
import Container from './Container';
import Navigation from './Navigation';
import MobileMenu from './MobileMenu';

function Header() {
  const { y } = useWindowScroll();

  return (
    <header
      className={classNames(
        'fixed top-0 left-0 right-0 z-[1] py-2 transition-transform duration-300',
        y > 100
          ? 'md:translate-y-0 backdrop-filter backdrop-blur-sm'
          : 'md:translate-y-12',
      )}
    >
      <Container className="flex flex-row-reverse md:flex-row justify-between items-center">
        <Link to="/" className="group">
          <span className="sr-only">Magnet Lounge</span>
          <LogoSVG className="w-auto h-12 md:h-16 text-white group-hover:opacity-80" />
        </Link>

        <Navigation className="hidden md:block" />
        <MobileMenu />
      </Container>
    </header>
  );
}

export default Header;
