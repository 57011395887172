/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

import locales from '../../config/locales';
import coverImage from '../assets/images/cover.jpg';

function SEO({
  description,
  lang = 'et',
  meta = [],
  title,
  location,
  children,
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `,
  );

  const metaTitle = title
    ? `${title} — Magnet Lounge`
    : site.siteMetadata.title;
  const metaDescription = description || site.siteMetadata.description;
  const url = location
    ? `${site.siteMetadata.siteUrl}${location}`
    : site.siteMetadata.siteUrl;

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={metaTitle}
        meta={[
          {
            name: `description`,
            content: metaDescription,
          },
          {
            property: `og:url`,
            content: url,
          },
          {
            property: `og:title`,
            content: metaTitle,
          },
          {
            property: `og:description`,
            content: metaDescription,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            property: `og:image`,
            content: `${site.siteMetadata.siteUrl}${coverImage}`,
          },
          {
            property: `og:author`,
            content: site.siteMetadata.author,
          },
          {
            property: `og:locale`,
            content: lang,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:creator`,
            content: site.siteMetadata.author,
          },
          {
            name: `twitter:title`,
            content: metaTitle,
          },
          {
            name: `twitter:description`,
            content: metaDescription,
          },
          {
            name: `twitter:image`,
            content: `${site.siteMetadata.siteUrl}${coverImage}`,
          },
        ].concat(meta)}
      />
      <Helmet>
        <link rel="canonical" href={url} />
        {locales.map((locale) => {
          return (
            <link
              key={locale.code}
              rel="alternate"
              hreflang={locale.code}
              href={`${site.siteMetadata.siteUrl}${locale.path}${
                locale.default ? '' : '/'
              }`}
            />
          );
        })}

        {children}
      </Helmet>
    </>
  );
}

export default SEO;
