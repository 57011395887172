import * as React from 'react';
import get from 'lodash/get';

import { LocaleContext } from '../context';

const useTranslation = () => {
  const { locale, defaultLocale, translations } =
    React.useContext(LocaleContext);

  const t = React.useCallback(
    (key) => {
      if (!key) {
        console.warn(`[useTranslation] No key specified for translation`);
        return '';
      }

      const translation = get(translations, key);

      if (translation === undefined) {
        console.warn(`[useTranslation] No translation found for ${key}`);
        return '';
      }

      return translation;
    },
    [translations],
  );

  return { t, locale, defaultLocale, translations };
};

export default useTranslation;
