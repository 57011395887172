import * as React from 'react';

import FacebookSVG from '../assets/svg/facebook-square-brands.svg';
import InstagramSVG from '../assets/svg/instagram-brands.svg';

function Footer() {
  return (
    <footer className="py-8 relative z-[1]">
      <ul className="flex items-center justify-center space-x-2">
        <li>
          <a
            href="https://www.facebook.com/Magnet-Lounge-1930325353923993"
            target="_blank"
            rel="noopener noreferrer"
            className="group"
          >
            <span className="sr-only">Facebook page of Magnet Lounge</span>
            <FacebookSVG className="h-8 w-8 group-hover:opacity-80" />
          </a>
        </li>
        <li>
          <a
            href="https://www.instagram.com/magnet.lounge/"
            target="_blank"
            rel="noopener noreferrer"
            className="group"
          >
            <span className="sr-only">Instagram of Magnet Lounge</span>
            <InstagramSVG className="h-8 w-8 group-hover:opacity-80" />
          </a>
        </li>
      </ul>
    </footer>
  );
}

export default Footer;
